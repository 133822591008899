import React from "react";
import PropTypes from "prop-types";
import PageMenu from "../components/pagemenu";
import Image from "../components/image";
import { MDXProvider } from "@mdx-js/react";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/vsDark";
import "./layout.css";

const PageLayout = ({ children }) => {
  const components = {
    h2: props => (
      //eslint-disable-next-line
      <h2
        {...props}
        style={{
          color: "#333",
          fontSize: "14pt",
          fontWeight: "bold",
          marginTop: "30px",
          marginBottom: "20px",
        }}
      />
    ),
    p: props => <p {...props} style={{ color: "#333", lineHeight: 1.5 }} />,
    "p.inlineCode": props => (
      <code
        style={{
          backgroundColor: "#f2f2f2",
          color: "#333",
          fontWeight: "bold",
        }}
        {...props}
      />
    ),
    ul: props => (
      //eslint-disable-next-line
      <ul
        {...props}
        style={{
          color: "#333",
          listStyleType: "circle",
          listStylePosition: "inside",
        }}
      />
    ),
    li: props => (
      //eslint-disable-next-line
      <li
        {...props}
        style={{
          marginBottom: "10px",
        }}
      />
    ),
    pre: props => {
      const className = props.children.props.className || "";
      const language = className.replace(/language-/, "");

      return (
        <Highlight
          {...defaultProps}
          theme={theme}
          code={props.children.props.children.trim()}
          language={language}
        >
          {({ className, tokens, getLineProps, getTokenProps }) => (
            <pre
              className={className}
              style={{
                backgroundColor: "#333",
                lineHeight: 1.5,
                margin: "20px 0 20px 0",
                padding: "10px",
                maxHeight: "550px",
                overflow: "scroll",
                borderRadius: "10px",
                boxShadow: "2px 2px 10px 1px rgba(0, 0, 0, 0.5)",
              }}
            >
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      );
    },
  };

  return (
    <div id="page-container">
      <div id="menu-bar">
        <PageMenu />
      </div>
      <div id="page-content">
        <MDXProvider components={components}>
          <main>{children}</main>
        </MDXProvider>
      </div>
      <footer>
        <p>© {new Date().getFullYear()} Stefano Picker</p>
        <a
          href="https://creativecommons.org/licenses/by-sa/4.0/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image name="license" />
        </a>
      </footer>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
